<template>
  <div class="animated fadeIn">
    <b-card style="width:100%; height:65vh; border:0;">
      <h2 class="mb-4">Koval Bright Directions 529 College Savings Program</h2>
      <b-tabs content-class="mt-3" justified style="width:100%; border:0;">
        <b-tab title="General Information" style="height:100%" active>
          <b-row>
            <b-col cols="2">
              <inline-date-picker
                id="input:renewal_date"
                :value-single="data['Renewal Date']"
                :readonly="!$permitted('input:renewal_date').write"
                label="Plan Renewal Date"
                :mode="$constants.FORM_MODE.VIEW"
                @changed="updateRenewalDate"
              />
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="2">
              Documents:
            </b-col>
          </b-row>
          <hr />
          <iframe
            src="https://drive.google.com/embeddedfolderview?id=1ns-wK2z6X0DU0bwHVSQpr0cAS2f9_D6F"
            style="width:100%; height:100%; border:0;"
          ></iframe>
        </b-tab>
        <b-tab title="Eligibility">
          <ul class="w-50">
            <li>Full-time and part-time position</li>
            <li>
              You will become eligible on the first month after completion of
              the mandatory introductory period (3 months)
            </li>
          </ul>
        </b-tab>
        <b-tab title="Internal Contact">
          <ul class="w-50">
            <li>
              Sebastian Saller (sebastian.saller@kovaldistillery.com)
            </li>
          </ul>
        </b-tab>
        <b-tab title="Provider Information">
          <ul class="w-50">
            <li>Merrill Lynch Wealth Management</li>
            <li>Kyle Mackey: kyle.mackey@ml.com</li>
          </ul>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      data: {
        "Renewal Date": ""
      }
    };
  },
  created() {},
  mounted() {
    this.fetchData();
  },

  methods: {
    async fetchData() {
      this.data = await this.$api.get("benefit/bright-directions");
    },
    async updateRenewalDate(e) {
      this.data["Renewal Date"] = e.valueSingleAsString;

      await this.$api.put("benefit/bright-directions", this.data);
    }
  }
};
</script>

<style scoped>
::v-deep .tab-content {
  height: 90%;
}

::v-deep .tabs {
  height: 100%;
}
</style>
